import { render, staticRenderFns } from "./CiqFinancialTableWrapper.vue?vue&type=template&id=b3155cc4&scoped=true"
import script from "./CiqFinancialTableWrapper.vue?vue&type=script&setup=true&lang=js"
export * from "./CiqFinancialTableWrapper.vue?vue&type=script&setup=true&lang=js"
import style0 from "./CiqFinancialTableWrapper.vue?vue&type=style&index=0&id=b3155cc4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_babel-core@6.26.3_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prett_mslvjkx5fqxq5hwkswdqi36zjm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3155cc4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CiqTableOptions: require('/vercel/path0/components/CiqTableOptions.vue').default,CiqFinancialSegmentTable: require('/vercel/path0/components/CiqFinancialSegmentTable.vue').default,CiqFinancialTable: require('/vercel/path0/components/CiqFinancialTable.vue').default,StyledTabs: require('/vercel/path0/components/StyledTabs.vue').default})
