import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import { isEmpty } from "lodash";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters"; /**
                                                                       *
                                                                       * Custom composition function for handling Financial Modeling Prep (FMP) financial data.
                                                                       * @param {Object} props - Props object.
                                                                       * @param {Ref} props.period - Reactive reference to the selected financial period.
                                                                       * @param {Ref} props.activeStatement - Reactive reference to the active financial statement.
                                                                       * @returns {Object} - An object containing the following computed properties:
                                                                       *   - fmpFinancials: The financial data for the selected period.
                                                                       *   - fmpStatement: The financial statement object for the active statement.
                                                                       *   - availableFmpDates: An array of formatted dates available for the selected period.
                                                                       *   - fmpSegmentData: The segment result data for the selected period.
                                                                       */
var fmpUtils = function fmpUtils() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var _useDateTimeFormatter = useDateTimeFormatters(),
    utcEpochToShortDate = _useDateTimeFormatter.utcEpochToShortDate,
    utcEpochToTwoDigitYear = _useDateTimeFormatter.utcEpochToTwoDigitYear;
  var fmpFinancials = computed(function () {
    var finData = $store.state.fmp.financials;
    var period = props.period.value;
    if (finData[period]) {
      return finData[period];
    } else {
      return {};
    }
  });
  var fmpStatement = computed(function () {
    var _fmpFinancials$value;
    var activeStatement = props.activeStatement.value;
    var fmpStatementObj = (_fmpFinancials$value = fmpFinancials.value) === null || _fmpFinancials$value === void 0 || (_fmpFinancials$value = _fmpFinancials$value.financialLineitems) === null || _fmpFinancials$value === void 0 ? void 0 : _fmpFinancials$value[activeStatement];
    if (fmpStatementObj) {
      return fmpStatementObj;
    } else {
      return {};
    }
  });
  var availableFmpDates = computed(function () {
    var finDataForPeriod = fmpFinancials.value;
    var dates = [];
    if (finDataForPeriod.dateArr) {
      dates = finDataForPeriod.dateArr;
    }
    var formattedDates = dates.map(function (d) {
      var dateObj = _objectSpread({}, d);
      dateObj.text = utcEpochToShortDate.value.format(dateObj.dateEpoch);
      dateObj.annualLabel = "'".concat(utcEpochToTwoDigitYear.value.format(dateObj.dateEpoch));
      dateObj.quarterLabel = "".concat(dateObj.quarterStr.substring(1), "Q").concat(utcEpochToTwoDigitYear.value.format(dateObj.dateEpoch));
      return dateObj;
    });
    return formattedDates.reverse();
  });
  var fmpSegmentData = computed(function () {
    var period = props.period.value;
    var finData = $store.state.fmp.financials;
    if (finData[period]) {
      return finData[period].segResultObj || [];
    } else {
      return [];
    }
  });
  var fmpSegmentsExist = computed(function () {
    return !isEmpty(fmpSegmentData);
  });
  return {
    fmpFinancials: fmpFinancials,
    fmpStatement: fmpStatement,
    availableFmpDates: availableFmpDates,
    fmpSegmentData: fmpSegmentData,
    fmpSegmentsExist: fmpSegmentsExist
  };
};
export default fmpUtils;