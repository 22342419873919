var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',[_c('table',{staticClass:"fintab"},[_c('colgroup',[_c('col'),_vm._v(" "),_vm._l((_vm.cleanDateHeaders),function(da){return _c('col',{key:da.value + 'header',staticClass:"datacol"})})],2),_vm._v(" "),_c('thead',[_c('tr',{staticClass:"tableheader"},[_c('th',{class:{
            fixedfirstheader: _vm.freezeFirstColumn,
            description: !_vm.freezeFirstColumn,
            'py-1': true,
            'pl-1': true,
            'text-left': true,
          }},[_vm._v("\n          "+_vm._s(`${_vm.I18nFn(_vm.tbltitle)}`)+"\n        ")]),_vm._v(" "),_vm._l((_vm.dateHeaders),function(date,idx){return _c('th',{key:`${date.value}-${_vm.dataSource}-tikrHead`,class:{
            'text-right': true,
            'py-1': true,
            'pr-1': idx === _vm.dateHeaders.length - 1,
          }},[_vm._v("\n          "+_vm._s(date.text)+"\n        ")])})],2)]),_vm._v(" "),_c('tbody',[_vm._l((_vm.cleanLineitems),function(line,lidx){return [(line.formula === 'val')?[_c('tr',{key:lidx,staticClass:"single-cell",class:_vm.generateRowClasses({
                darkMode: _vm.darkActive,
                row: line,
              })},[_c('td',{class:{
                fixedfirstrow: _vm.freezeFirstColumn,
                description: !_vm.freezeFirstColumn,
                fixedfirstrowcolorlight: !_vm.darkActive,
                fixedfirstrowcolordark: _vm.darkActive,
              }},[_vm._v("\n              "+_vm._s(line?.dataitemname)+"\n            ")])]),_vm._v(" "),_vm._l((Object.keys(_vm.segDataObj[line?.dataitemid])),function(seg){return [(
                _vm.dataExistsForRow(
                  _vm.segDataObj[line?.dataitemid][seg],
                  _vm.cleanDateHeaders
                )
              )?_c('tr',{key:seg + line.dataitemid,class:_vm.generateRowClasses({
                  darkMode: _vm.darkActive,
                  row: line,
                  segmentName: seg,
                  title: false,
                }),on:{"click":function($event){return _vm.finSegRowClick(line, seg)}}},[_c('td',{class:{
                  fixedfirstrow: _vm.freezeFirstColumn,
                  description: !_vm.freezeFirstColumn,
                  fixedfirstrowcolorlight: !_vm.darkActive,
                  fixedfirstrowcolordark: _vm.darkActive,
                }},[_vm._v("\n                   "+_vm._s(seg)+"\n              ")]),_vm._v(" "),_vm._l((_vm.cleanDateHeaders),function(d){return _c('FinValueTblCell',{key:d.value + line?.dataitemid + seg,attrs:{"formatclass":_vm.formatFinancialClass(
                    _vm.segDataObj[line?.dataitemid][seg],
                    d.value,
                    line
                  ),"data-obj":_vm.segDataObj[line?.dataitemid][seg][d.value],"period":_vm.period,"lineitem":line,"desiredunits":_vm.desiredunits,"decimals":_vm.decimals,"exrate":d.exchangerate,"currencytoggle":_vm.currencytoggle,"showturns":_vm.showturns,"segments":true}})})],2):_vm._e()]})]:_c('tr',{key:lidx,class:_vm.generateRowClasses({ darkMode: _vm.darkActive, row: line })},[_c('td',{class:{
              fixedfirstrow: _vm.freezeFirstColumn,
              description: !_vm.freezeFirstColumn,
              fixedfirstrowcolorlight: !_vm.darkActive,
              fixedfirstrowcolordark: _vm.darkActive,
            }},[_vm._v("\n            "+_vm._s(line?.dataitemname)+"\n          ")])])]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }