import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import TableChartButton from "~/components/charts/TableChartButton.vue";
import StyledTabs from "~/components/StyledTabs";
import useBaseUtils from "~/functions/useBaseUtils";
import usePeriod from "~/functions/common/usePeriod";
import useCiqFinTableInChart from "~/functions/useCiqFinTableInChart";
import useCiqTableOptions from "~/functions/useCiqTableOptions";
import useCiqTableUtils from "~/functions/useCiqTableUtils";
import useFinancials from "~/functions/shared/useFinancials";
import useCIQFinancials from "~/functions/ciq/useCIQFinancials";
import useFMPFinancials from "~/functions/fmp/useFMPFinancials";
import useMorningstarFinancials from "~/functions/morningstar/useMorningstarFinancials";
import { fmpSegmentTemplate } from "~/utils/templates/fmp_templates";
import { financialTabs, findStatementIndex, dataSourceTabs } from "~/utils/constants/tabs";
import { extraRows } from "~/utils/ciq/common";
export default {
  __name: 'CiqFinancialTableWrapper',
  setup: function setup(__props) {
    var _$featureToggle$toggl, _$featureToggle$toggl2;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store,
      $nextTick = _useNuxtApp.$nextTick,
      $vuetify = _useNuxtApp.$vuetify,
      $featureToggle = _useNuxtApp.$featureToggle;
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn,
      refCode = _useBaseUtils.refCode,
      sectionWrapperStyle = _useBaseUtils.sectionWrapperStyle;
    var $route = useRoute();
    var _useCiqFinTableInChar = useCiqFinTableInChart(),
      transformFinRowToHighchart = _useCiqFinTableInChar.transformFinRowToHighchart,
      transformSegmentRowToHighchart = _useCiqFinTableInChar.transformSegmentRowToHighchart;
    var enableDataSourceQueryParams = (_$featureToggle$toggl = $featureToggle.toggles) === null || _$featureToggle$toggl === void 0 ? void 0 : _$featureToggle$toggl["datasource-query-params"];
    var showMorningstarFinancials = (_$featureToggle$toggl2 = $featureToggle.toggles) === null || _$featureToggle$toggl2 === void 0 ? void 0 : _$featureToggle$toggl2["show-morningstar-financials"];
    onMounted(function () {
      try {
        $nextTick(function () {
          try {
            $vuetify.goTo("#stocktabbar");
          } catch (error) {
            console.error("Error .goto from financialTableWrapper: ", error);
          }
        });
      } catch (error) {
        console.error("Error .goto from FinancialTable: ", error);
      }
    });
    var currentIso = computed(function () {
      var _currencies$value$cur;
      return ((_currencies$value$cur = currencies.value[currencyToggle.value]) === null || _currencies$value$cur === void 0 ? void 0 : _currencies$value$cur.code) || "USD";
    });
    var _useCiqTableOptions = useCiqTableOptions({
        storeModule: "ciq",
        tableType: "financials"
      }),
      decimals = _useCiqTableOptions.decimals,
      formatNumberToDecimals = _useCiqTableOptions.formatNumberToDecimals,
      desiredUnits = _useCiqTableOptions.desiredUnits,
      activePeriod = _useCiqTableOptions.activePeriod,
      currencyToggle = _useCiqTableOptions.currencyToggle,
      dateRange = _useCiqTableOptions.dateRange,
      makeDateLabels = _useCiqTableOptions.makeDateLabels,
      currencies = _useCiqTableOptions.currencies,
      reverseDates = _useCiqTableOptions.reverseDates,
      activeTableSelection = _useCiqTableOptions.activeTableSelection;
    var showAllEmptyRows = ref(false);
    var showTurns = ref(true);
    var units = [{
      text: "Displayed in thousands",
      value: 0,
      title: "k"
    }, {
      text: "Displayed in millions",
      value: 1,
      title: "MM"
    }, {
      text: "Displayed in billions",
      value: 2,
      title: "B"
    }];
    var tableSelectOptions = ref(dataSourceTabs(showMorningstarFinancials));
    var ticker = computed(function () {
      return $store.state.ciq.ticker;
    });
    var ciqCategories = computed(function () {
      return financialTabs.map(function (m) {
        var slugs = {
          fmp: "alt",
          morningstar: "ms",
          ciq: "tikr"
        };
        var datasetSlug = slugs[activeTableSelection.value];
        var toPath = enableDataSourceQueryParams ? "/stock/financials?cid=".concat(ticker.value.companyid, "&tid=").concat(ticker.value.tradingitemid, "&tab=").concat(m.slug, "&ref=").concat(refCode.value, "&dataset=").concat(datasetSlug) : "/stock/financials?cid=".concat(ticker.value.companyid, "&tid=").concat(ticker.value.tradingitemid, "&tab=").concat(m.slug, "&ref=").concat(refCode.value);
        return _objectSpread({
          to: toPath,
          nuxt: true,
          exact: true
        }, m);
      });
    });
    var initialStatementIndex = computed(function () {
      var tabQueryParam = $route.query.tab;
      var statementArr = ciqCategories.value;
      return findStatementIndex(statementArr, tabQueryParam);
    });
    var activeStatement = ref(initialStatementIndex.value);
    var _usePeriod = usePeriod({
        activePeriod: activePeriod,
        activeTableSelection: activeTableSelection
      }),
      period = _usePeriod.period,
      periods = _usePeriod.periods;
    var _useFMPFinancials = useFMPFinancials({
        period: period,
        activeStatement: activeStatement
      }),
      fmpFinancials = _useFMPFinancials.fmpFinancials,
      fmpStatement = _useFMPFinancials.fmpStatement,
      availableFmpDates = _useFMPFinancials.availableFmpDates,
      fmpSegmentData = _useFMPFinancials.fmpSegmentData,
      fmpSegmentsExist = _useFMPFinancials.fmpSegmentsExist;
    var _useCIQFinancials = useCIQFinancials({
        period: period,
        extraRows: extraRows
      }),
      availableCiqDates = _useCIQFinancials.availableCiqDates,
      asRptSegmentData = _useCIQFinancials.asRptSegmentData,
      resData = _useCIQFinancials.resData,
      financials = _useCIQFinancials.financials,
      dailyPriceFactors = _useCIQFinancials.dailyPriceFactors;
    var _useMorningstarFinanc = useMorningstarFinancials({
        period: period,
        activeStatement: activeStatement
      }),
      morningstarFinancials = _useMorningstarFinanc.morningstarFinancials,
      morningstarStatement = _useMorningstarFinanc.morningstarStatement,
      morningstarSegments = _useMorningstarFinanc.morningstarSegments,
      availableMorningstarDates = _useMorningstarFinanc.availableMorningstarDates;
    var _useFinancials = useFinancials({
        activeTableSelection: activeTableSelection,
        fmpFinancials: fmpFinancials,
        morningstarFinancials: morningstarFinancials,
        financials: financials,
        period: period
      }),
      fetching = _useFinancials.fetching,
      financialsError = _useFinancials.financialsError,
      financialsExist = _useFinancials.financialsExist,
      selectedRows = _useFinancials.selectedRows;
    var _useCiqTableUtils = useCiqTableUtils({
        period: period.value
      }),
      removeRowFromSelectedObject = _useCiqTableUtils.removeRowFromSelectedObject;
    var availableDates = computed(function () {
      if (activeTableSelection.value === "fmp") {
        return availableFmpDates.value;
      } else if (activeTableSelection.value === "morningstar") {
        return availableMorningstarDates.value;
      }
      return availableCiqDates.value;
    });
    var dateHeaders = computed(function () {
      var headers = availableDates.value.filter(function (_, i) {
        return i >= dateRange.value[0] && i <= dateRange.value[1];
      });
      if (reverseDates.value) {
        return headers.reverse();
      } // only for MS and annual periods
      if (activeTableSelection.value === "morningstar" && period.value === "a") {
        return headers.filter(function (item) {
          // filter out ltm column if there is no data
          var text = item.text,
            value = item.value;
          if (text === "LTM") {
            var _morningstarStatement;
            var keys = (_morningstarStatement = morningstarStatement.value) === null || _morningstarStatement === void 0 ? void 0 : _morningstarStatement.keys; // Handle Morningstar Segments
            if (!keys) return false;
            var validItems = keys.reduce(function (acc, key) {
              // filtering out titles
              if (key.tikrKey) {
                var _morningstarFinancial;
                var metric = (_morningstarFinancial = morningstarFinancials.value) === null || _morningstarFinancial === void 0 || (_morningstarFinancial = _morningstarFinancial.resData) === null || _morningstarFinancial === void 0 || (_morningstarFinancial = _morningstarFinancial[key.tikrKey]) === null || _morningstarFinancial === void 0 ? void 0 : _morningstarFinancial[value];
                if (metric) {
                  acc = acc + 1;
                }
              }
              return acc;
            }, 0);
            return validItems === 0 ? false : item;
          }
          return item;
        });
      }
      return headers;
    });
    var dateHeadersForHighcharts = computed(function () {
      if (reverseDates.value) {
        return _toConsumableArray(dateHeaders.value).reverse();
      }
      return dateHeaders.value;
    });
    var handleTabChange = function handleTabChange(_, index) {
      activeStatement.value = index;
    };
    var rowsForChart = computed(function () {
      var splitRows = Object.keys(selectedRows.value).reduce(function (acc, r) {
        var rowType = r.split("-")[0];
        if (rowType === "val" || rowType === "turns" || rowType === "sum") {
          acc[0].push(r);
        } else {
          acc[1].push(r);
        }
        return acc;
      }, [[], []]);
      var orgRows = splitRows[0].concat(splitRows[1]);
      return orgRows.map(function (rowId) {
        var _fmpFinancials$value, _morningstarFinancial2;
        var _rowId$split = rowId.split("-"),
          _rowId$split2 = _slicedToArray(_rowId$split, 1),
          splitRow = _rowId$split2[0];
        if (splitRow === "asRpt") {
          return transformSegmentRowToHighchart(rowId, selectedRows === null || selectedRows === void 0 ? void 0 : selectedRows.value[rowId], dateHeadersForHighcharts.value, asRptSegmentData.value, desiredUnits.value, currencyToggle.value, formatNumberToDecimals.value);
        } else if (splitRow === "fmpSeg") {
          return transformSegmentRowToHighchart(rowId, selectedRows === null || selectedRows === void 0 ? void 0 : selectedRows.value[rowId], dateHeadersForHighcharts.value, fmpSegmentData.value, desiredUnits.value, currencyToggle.value, formatNumberToDecimals.value);
        } else if (splitRow === "morningstarSeg") {
          return transformSegmentRowToHighchart(rowId, selectedRows === null || selectedRows === void 0 ? void 0 : selectedRows.value[rowId], dateHeadersForHighcharts.value, morningstarSegments.value.data, desiredUnits.value, currencyToggle.value, formatNumberToDecimals.value);
        }
        var currentFinancials = activeTableSelection.value === "fmp" ? (_fmpFinancials$value = fmpFinancials.value) === null || _fmpFinancials$value === void 0 ? void 0 : _fmpFinancials$value.resultObj : activeTableSelection.value === "morningstar" ? (_morningstarFinancial2 = morningstarFinancials.value) === null || _morningstarFinancial2 === void 0 ? void 0 : _morningstarFinancial2.resData : resData.value;
        return transformFinRowToHighchart({
          periodType: period.value,
          resData: currentFinancials,
          dateHeaders: dateHeadersForHighcharts.value,
          row: selectedRows === null || selectedRows === void 0 ? void 0 : selectedRows.value[rowId],
          desiredUnits: desiredUnits.value,
          currencyToggle: currencyToggle.value,
          rowId: rowId,
          formatNumber: formatNumberToDecimals.value
        });
      });
    }); // TODO: refactor to use Clipboard API
    var copyTable = function copyTable() {
      try {
        showTurns.value = false;
        $nextTick(function () {
          var table = document.querySelector("table.fintab");
          var range = document.createRange(); // FIXME: change table.thead.style?
          var sel = window.getSelection();
          sel.removeAllRanges();
          range.selectNode(table);
          sel.addRange(range);
          document.execCommand("copy");
          showTurns.value = true;
        });
      } catch (error) {
        console.error("Error copying table: ", error);
      }
    };
    var removeRow = function removeRow(rowId) {
      // FIXME: this is listening to an event from the chart table button but should be within that component
      removeRowFromSelectedObject(rowId, period.value, "financialsChart", activeTableSelection.value);
    };
    watch(function () {
      return $route.query.tab;
    }, function (slug) {
      activeStatement.value = findStatementIndex(ciqCategories.value, slug);
    });
    return {
      __sfc: true,
      $store: $store,
      $nextTick: $nextTick,
      $vuetify: $vuetify,
      $featureToggle: $featureToggle,
      I18nFn: I18nFn,
      refCode: refCode,
      sectionWrapperStyle: sectionWrapperStyle,
      $route: $route,
      transformFinRowToHighchart: transformFinRowToHighchart,
      transformSegmentRowToHighchart: transformSegmentRowToHighchart,
      enableDataSourceQueryParams: enableDataSourceQueryParams,
      showMorningstarFinancials: showMorningstarFinancials,
      currentIso: currentIso,
      decimals: decimals,
      formatNumberToDecimals: formatNumberToDecimals,
      desiredUnits: desiredUnits,
      activePeriod: activePeriod,
      currencyToggle: currencyToggle,
      dateRange: dateRange,
      makeDateLabels: makeDateLabels,
      currencies: currencies,
      reverseDates: reverseDates,
      activeTableSelection: activeTableSelection,
      showAllEmptyRows: showAllEmptyRows,
      showTurns: showTurns,
      units: units,
      tableSelectOptions: tableSelectOptions,
      ticker: ticker,
      ciqCategories: ciqCategories,
      initialStatementIndex: initialStatementIndex,
      activeStatement: activeStatement,
      period: period,
      periods: periods,
      fmpFinancials: fmpFinancials,
      fmpStatement: fmpStatement,
      availableFmpDates: availableFmpDates,
      fmpSegmentData: fmpSegmentData,
      fmpSegmentsExist: fmpSegmentsExist,
      availableCiqDates: availableCiqDates,
      asRptSegmentData: asRptSegmentData,
      resData: resData,
      financials: financials,
      dailyPriceFactors: dailyPriceFactors,
      morningstarFinancials: morningstarFinancials,
      morningstarStatement: morningstarStatement,
      morningstarSegments: morningstarSegments,
      availableMorningstarDates: availableMorningstarDates,
      fetching: fetching,
      financialsError: financialsError,
      financialsExist: financialsExist,
      selectedRows: selectedRows,
      removeRowFromSelectedObject: removeRowFromSelectedObject,
      availableDates: availableDates,
      dateHeaders: dateHeaders,
      dateHeadersForHighcharts: dateHeadersForHighcharts,
      handleTabChange: handleTabChange,
      rowsForChart: rowsForChart,
      copyTable: copyTable,
      removeRow: removeRow,
      TableChartButton: TableChartButton,
      StyledTabs: StyledTabs,
      fmpSegmentTemplate: fmpSegmentTemplate
    };
  }
};