import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
import useCiqValuationTimeSeries from "~/functions/useCiqValuationTimeSeries";
import { SHOW_PRICE_FACTORS } from "~/feature-toggle"; /**
                                                       * Custom composition function for handling CIQ financial data.
                                                       * @param {Object} props - Props object containing the 'period' property.
                                                       * @param {Ref} props.period - Reactive reference to the period
                                                       * @returns {Object} An object containing the following computed properties:
                                                       *   - availableCiqDates: An array of formatted date objects.
                                                       *   - asRptSegmentData: An array of 'asRptSegData' for the specified period.
                                                       *   - resData: An array of 'resData' for the specified period.
                                                       *   - financials: An array of 'financials' for the specified period.
                                                       */
var useCIQFinancials = function useCIQFinancials() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var _useDateTimeFormatter = useDateTimeFormatters(),
    utcEpochToShortDate = _useDateTimeFormatter.utcEpochToShortDate,
    utcEpochToTwoDigitYear = _useDateTimeFormatter.utcEpochToTwoDigitYear;
  var getFinancials = function getFinancials(_ref) {
    var period = _ref.period,
      finData = _ref.finData;
    if (finData[period]) {
      return finData[period].financials || [];
    }
    return [];
  };
  var availableCiqDates = computed(function () {
    var finData = $store.state.ciq.financials;
    var dates = [];
    if (finData[props.period.value]) {
      dates = finData[props.period.value].dates || [];
    } /**
      * TODO:
      * abstract calculation of parseTime(d.fiperiodenddate) to backend transformation function
      * move any / all transformations that DO NOT involve date formatters to the transformationfunction too
      */
    return dates.map(function (d) {
      var dateObj = _objectSpread({}, d);
      dateObj.text = utcEpochToShortDate.value.format(dateObj.epoch);
      dateObj.annualLabel = "'".concat(utcEpochToTwoDigitYear.value.format(dateObj.epoch));
      dateObj.quarterLabel = "".concat(dateObj.calendarquarter, "Q").concat(utcEpochToTwoDigitYear.value.format(dateObj.epoch));
      return dateObj;
    });
  });
  var asRptSegmentData = computed(function () {
    var period = props.period.value;
    var finData = $store.state.ciq.financials;
    if (finData[period]) {
      return finData[period].asRptSegData || [];
    }
    return [];
  });
  var resData = computed(function () {
    var period = props.period.value;
    var finData = $store.state.ciq.financials;
    if (finData[period]) {
      return finData[period].resData || [];
    }
    return [];
  });
  var dailyPriceFactors = computed(function () {
    var _props$extraRows;
    if (!(props !== null && props !== void 0 && (_props$extraRows = props.extraRows) !== null && _props$extraRows !== void 0 && _props$extraRows.length) || !SHOW_PRICE_FACTORS) {
      return {};
    }
    var _useCiqValuationTimeS = useCiqValuationTimeSeries({
        tableType: "financials",
        rows: props.extraRows,
        periodType: props.period.value
      }),
      dailyPriceFactorsForHighChart = _useCiqValuationTimeS.dailyPriceFactorsForHighChart;
    return dailyPriceFactorsForHighChart.value;
  });
  var financials = computed(function () {
    var storeFinancials = getFinancials({
      period: props.period.value,
      finData: $store.state.ciq.financials
    });
    if (props.extraRows.length) {
      return storeFinancials.reduce(function (acc, item, idx) {
        var isIncomeStatementTab = idx === 0;
        if (isIncomeStatementTab) {
          acc.push([].concat(_toConsumableArray(item), _toConsumableArray(extraData.value)));
          return acc;
        }
        acc.push(item);
        return acc;
      }, []);
    }
    return storeFinancials;
  });
  var extraData = computed(function () {
    var _props$extraRows2;
    if (!((_props$extraRows2 = props.extraRows) !== null && _props$extraRows2 !== void 0 && _props$extraRows2.length)) {
      return [];
    }
    var storeFinancials = getFinancials({
      period: props.period.value,
      finData: $store.state.ciq.financials
    });
    var firstTabItems = storeFinancials === null || storeFinancials === void 0 ? void 0 : storeFinancials[0];
    if (firstTabItems) {
      var highestIndex = firstTabItems.reduce(function (acc, item) {
        if (item.lineorder > acc) {
          acc = item.lineorder;
        }
        return acc;
      }, 0);
      return getExtraData({
        keys: props.extraRows,
        period: props.period.value,
        initialIndex: highestIndex
      });
    }
    return [];
  });
  var getExtraData = function getExtraData(_ref2) {
    var keys = _ref2.keys,
      period = _ref2.period,
      initialIndex = _ref2.initialIndex;
    var _useCiqValuationTimeS2 = useCiqValuationTimeSeries({
        tableType: "financials",
        rows: keys,
        periodType: period
      }),
      priceFactorsForTables = _useCiqValuationTimeS2.priceFactorsForTables;
    var currentPeriodPriceFactors = priceFactorsForTables.value[period] || {};
    var mappedPriceFactors = Object.values(currentPeriodPriceFactors).reduce(function (acc, obj, i) {
      var item = keys[i];
      var isStockPrice = item.name === "Price Close";
      acc.push(_objectSpread(_objectSpread({}, obj), {}, {
        dataitemid: item.dailyId,
        name: item.name,
        lineorder: initialIndex + i,
        tikrdisplay: "1",
        format: item.format || "normal",
        formula: "val",
        multiplyby: 1,
        datasource: "valuation",
        yAxis: isStockPrice ? 2 : 3,
        seriesType: "line"
      }));
      return acc;
    }, []);
    return mappedPriceFactors;
  };
  return {
    availableCiqDates: availableCiqDates,
    asRptSegmentData: asRptSegmentData,
    resData: resData,
    financials: financials,
    dailyPriceFactors: dailyPriceFactors
  };
};
export default useCIQFinancials;