import "core-js/modules/es.object.keys.js";
/**
 * Custom composition function for handling financial data from either FMP or CIQ.
 * @param {Object} props - Props object.
 * @param {Ref} props.activeTableSelection - Reactive reference to the active table selection ("fmp" or "ciq").
 * @param {Ref} props.period - Reactive reference to the selected financial period.
 * @param {Ref} props.fmpFinancials - Reactive reference to array containing the FMP financial data.
 * @param {Ref} props.morningstarFinancials - Reactive reference to array containing the Morningstar financial data.
 * @param {Array} props.financials - An array of CIQ financial data.
 *
 * @returns {Object} - An object containing computed properties:
 *   - fetching: Indicates whether financial data is being fetched.
 *   - error: Any error that occurred while fetching financial data.
 *   - financialsExist: Indicates whether financial data exists.
 */
var useFinancials = function useFinancials() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var fetching = computed(function () {
    if (props.activeTableSelection.value === "fmp") {
      var _$store$state$fmp$loa;
      return (_$store$state$fmp$loa = $store.state.fmp.loading) === null || _$store$state$fmp$loa === void 0 ? void 0 : _$store$state$fmp$loa.financials;
    }
    if (props.activeTableSelection.value === "morningstar") {
      var _$store$state$morning;
      return (_$store$state$morning = $store.state.morningstar.loading) === null || _$store$state$morning === void 0 ? void 0 : _$store$state$morning.financials;
    }
    return $store.state.ciq.fetchingFinancials;
  });
  var financialsError = computed(function () {
    var _$store$state;
    return (_$store$state = $store.state) === null || _$store$state === void 0 ? void 0 : _$store$state[props.activeTableSelection.value].financialsError;
  });
  var financialsExist = computed(function () {
    if (props.activeTableSelection.value === "fmp") {
      var _props$fmpFinancials$;
      var metricObj = ((_props$fmpFinancials$ = props.fmpFinancials.value) === null || _props$fmpFinancials$ === void 0 ? void 0 : _props$fmpFinancials$.resultObj) || {};
      return Object.keys(metricObj).length > 0;
    } else if (props.activeTableSelection.value === "morningstar") {
      var _props$morningstarFin;
      var _metricObj = ((_props$morningstarFin = props.morningstarFinancials.value) === null || _props$morningstarFin === void 0 ? void 0 : _props$morningstarFin.resData) || {};
      return Object.keys(_metricObj).length > 0;
    }
    return props.financials.length !== 0;
  });
  var selectedRows = computed(function () {
    var _$store$state2;
    var period = props.period.value;
    var finData = (_$store$state2 = $store.state) === null || _$store$state2 === void 0 ? void 0 : _$store$state2[props.activeTableSelection.value].financialsChart;
    if (finData[period]) {
      return finData[period] || {};
    } else {
      return {};
    }
  });
  return {
    fetching: fetching,
    financialsError: financialsError,
    financialsExist: financialsExist,
    selectedRows: selectedRows
  };
};
export default useFinancials;