var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{attrs:{"dense":""}},[(!_setup.fetching)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","order":"1"}},[_c('v-card',{style:(_setup.sectionWrapperStyle),attrs:{"elevation":"0"}},[_c('CiqTableOptions',{attrs:{"table-type":"financials","active-statement":_setup.activeStatement,"available-dates":_setup.availableDates,"date-headers":_setup.dateHeaders,"fluid":true,"periods":_setup.periods,"show-all-empty-rows":_setup.showAllEmptyRows,"table-select-options":_setup.tableSelectOptions,"tick-labels":_setup.availableDates.map(_setup.makeDateLabels),"ticker":_setup.ticker},on:{"copytable":_setup.copyTable,"showallemptyrows":function($event){_setup.showAllEmptyRows = !_setup.showAllEmptyRows}}})],1)],1),_vm._v(" "),(_setup.financialsExist)?[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","order":"2"}},[_c('v-card',{staticClass:"py-3 mt-4",style:(_setup.sectionWrapperStyle),attrs:{"elevation":"0"}},[_c(_setup.TableChartButton,{attrs:{"selectedrows":_setup.rowsForChart,"dateheaders":_setup.dateHeaders,"columndecimals":_setup.decimals,"columnunits":_setup.units[_setup.desiredUnits].title,"desiredunits":_setup.desiredUnits,"columnisocode":_setup.currencies[_setup.currencyToggle]
                  ? _setup.currencies[_setup.currencyToggle].code
                  : 'USD',"period":_setup.period,"charttitle":_setup.ticker.tickersymbol
                  ? `${_setup.ticker.companyname} (${_setup.ticker.tickersymbol})`
                  : '',"chartsymbol":_setup.ticker.tickersymbol,"data-source":_setup.activeTableSelection,"extra-rows":_setup.dailyPriceFactors},on:{"removerow":_setup.removeRow}})],1)],1),_vm._v(" "),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","order":"3"}},[_c('v-card',{staticClass:"mt-4 pt-2",style:(_setup.sectionWrapperStyle),attrs:{"elevation":"0"}},[_c(_setup.StyledTabs,{attrs:{"tablist":_setup.ciqCategories,"show-arrows":true,"old-layout":true},on:{"change":_setup.handleTabChange}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"tables-wrapper",attrs:{"cols":"12","justify":"center","align":"center"}},_vm._l((_setup.ciqCategories),function(statement,statementIndex){return _c('v-responsive',{directives:[{name:"show",rawName:"v-show",value:(statementIndex === _setup.activeStatement),expression:"statementIndex === activeStatement"}],key:`${statement.statement}-${_setup.activeTableSelection}-tikrFinancial`,staticClass:"tblcontainer d-inline-block",class:{
                        lighttableshadows: !_setup.$vuetify.theme.dark,
                        active: statementIndex === _setup.activeStatement,
                      },attrs:{"max-height":"90vh"}},[(_setup.activeStatement === 4 && statementIndex === 4)?[(_setup.activeTableSelection === 'ciq')?_c('v-card',[_c('v-container',[_c('v-row',[(_setup.fmpSegmentsExist)?_c('v-col',[_vm._v("\n                                "+_vm._s(`${_setup.I18nFn(
                                    "Sorry, the segments table is currently not available for"
                                  )} ${_setup.ticker.companyname || ""} (${
                                    _setup.ticker.tickersymbol || ""
                                  }),  ${_setup.I18nFn(
                                    "try our 'Secondary Data' source on the template selection above"
                                  )}`)+"\n                              ")]):_c('v-col',[_vm._v("\n                                "+_vm._s(`${_setup.I18nFn(
                                    "Sorry, the segments table is currently not available for"
                                  )} ${_setup.ticker.companyname || ""} (${
                                    _setup.ticker.tickersymbol || ""
                                  })`)+"\n                              ")])],1)],1)],1):(
                            _setup.activeTableSelection === 'fmp' &&
                            !_setup.fmpSegmentsExist
                          )?_c('v-card',[_c('v-container',[_c('v-row',[_c('v-col',[_vm._v("\n                                "+_vm._s(`${_setup.I18nFn(
                                    "Our 'Secondary Data' source does not have information for"
                                  )}  ${_setup.ticker.companyname || ""} ${
                                    _setup.ticker.tickersymbol || ""
                                  }`)+"\n                              ")])],1)],1)],1):(
                            _setup.showMorningstarFinancials &&
                            _setup.activeTableSelection === 'morningstar'
                          )?_c('CiqFinancialSegmentTable',{attrs:{"date-headers":_setup.dateHeaders,"lineitems":_setup.morningstarSegments.template.keys,"seg-data-obj":_setup.morningstarSegments.data,"desiredunits":_setup.desiredUnits,"decimals":_setup.decimals,"currencytoggle":_setup.currencyToggle,"showturns":_setup.showTurns,"period":_setup.period,"data-source":"morningstar","seg-type":"morningstarSeg"}}):_c('CiqFinancialSegmentTable',{attrs:{"date-headers":_setup.dateHeaders,"lineitems":_setup.fmpSegmentTemplate.keys,"seg-data-obj":_setup.fmpSegmentData,"desiredunits":_setup.desiredUnits,"decimals":_setup.decimals,"currencytoggle":_setup.currencyToggle,"showturns":_setup.showTurns,"period":_setup.period,"data-source":"fmp","seg-type":"fmpSeg"}})]:(
                          statementIndex === _setup.activeStatement &&
                          _setup.activeTableSelection === 'fmp'
                        )?_c('CiqFinancialTable',{key:`fmp-${_setup.fmpStatement.statement}`,attrs:{"tbltitle":_setup.fmpStatement.statement,"date-headers":_setup.dateHeaders,"lineitems":_setup.fmpStatement.keys,"desired-units":_setup.desiredUnits,"decimals":_setup.decimals,"currency-toggle":_setup.currencyToggle,"show-turns":_setup.showTurns,"period":_setup.period,"show-all-empty-rows":_setup.showAllEmptyRows,"data-source":"fmp"}}):(
                          _setup.showMorningstarFinancials &&
                          statementIndex === _setup.activeStatement &&
                          _setup.activeTableSelection === 'morningstar'
                        )?_c('CiqFinancialTable',{key:`morningstar-${_setup.morningstarStatement.statement}`,attrs:{"tbltitle":_setup.morningstarStatement.statement,"date-headers":_setup.dateHeaders,"lineitems":_setup.morningstarStatement.keys,"desired-units":_setup.desiredUnits,"decimals":_setup.decimals,"currency-toggle":_setup.currencyToggle,"show-turns":_setup.showTurns,"metric-obj":_setup.morningstarFinancials.resData,"period":_setup.period,"show-all-empty-rows":_setup.showAllEmptyRows,"data-source":"morningstar"}}):(statementIndex === _setup.activeStatement)?_c('CiqFinancialTable',{key:`ciq-${statement.statement.trim()}`,attrs:{"tbltitle":statement.statement.trim(),"date-headers":_setup.dateHeaders,"lineitems":_setup.financials[statementIndex],"metric-obj":_setup.resData,"desired-units":_setup.desiredUnits,"decimals":_setup.decimals,"currency-toggle":_setup.currencyToggle,"show-turns":_setup.showTurns,"period":_setup.period,"show-all-empty-rows":_setup.showAllEmptyRows,"show-ltm":_setup.activePeriod === 0,"current-iso":_setup.currentIso}}):_vm._e()],2)}),1)],1)],1)],1)],1)],1)]:_vm._e()],2),_vm._v(" "),(_setup.financialsError)?_c('v-row',{attrs:{"dense":""}},[(_setup.activeTableSelection === 'ciq')?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mt-4 py-2",style:(_setup.sectionWrapperStyle),attrs:{"elevation":"0"}},[_vm._v("\n          "+_vm._s(_setup.I18nFn("Error Fetching Data"))+"\n        ")])],1):_vm._e(),_vm._v(" "),(_setup.activeTableSelection === 'fmp')?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mt-4 py-2",style:(_setup.sectionWrapperStyle),attrs:{"elevation":"0"}},[_vm._v("\n          "+_vm._s(`${_setup.I18nFn("Data for")} ${_setup.ticker.companyname || ""} (${
              _setup.ticker.tickersymbol || ""
            }) ${_setup.I18nFn("is not available from our 'Secondary Data' source")}`)+"\n        ")])],1):_vm._e(),_vm._v(" "),(_setup.activeTableSelection === 'morningstar')?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mt-4 py-2",style:(_setup.sectionWrapperStyle),attrs:{"elevation":"0"}},[_vm._v("\n          "+_vm._s(`${_setup.I18nFn("Data for")} ${_setup.ticker.companyname || ""} (${
              _setup.ticker.tickersymbol || ""
            }) ${_setup.I18nFn(
              "is not available from our 'Morningstar Data' source"
            )}`)+"\n        ")])],1):_vm._e()],1):_vm._e()],1):_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-card',{style:(_setup.sectionWrapperStyle),attrs:{"elevation":"0"}},[_c('v-container',[_c('v-progress-circular',{staticClass:"quote",attrs:{"size":70,"width":7,"color":"primaryAction","indeterminate":""}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }