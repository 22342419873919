import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import useBaseUtils from "~/functions/useBaseUtils";
import useCiqTableUtils from "~/functions/useCiqTableUtils";
export default defineComponent({
  props: {
    tbltitle: {
      type: String,
      "default": "Segments, As Reported"
    },
    dateHeaders: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    lineitems: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    segDataObj: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    desiredunits: {
      type: Number,
      "default": 1
    },
    decimals: {
      type: Number,
      "default": 2
    },
    currencytoggle: {
      type: Number,
      "default": 0
    },
    showturns: {
      type: Boolean,
      "default": true
    },
    segType: {
      type: String,
      "default": "asRpt"
    },
    period: {
      type: String,
      "default": "a"
    },
    dataSource: {
      type: String,
      "default": "ciq"
    }
  },
  setup: function setup(props) {
    // use function below to create row classes
    // it is a function because one must check if the row has been clicked
    var _useCiqTableUtils = useCiqTableUtils(props),
      selectedCIQFinancialRows = _useCiqTableUtils.selectedCIQFinancialRows,
      selectedFMPFinancialRows = _useCiqTableUtils.selectedFMPFinancialRows,
      selectedMorningstarFinancialRows = _useCiqTableUtils.selectedMorningstarFinancialRows,
      addRowToSelectedObject = _useCiqTableUtils.addRowToSelectedObject,
      removeRowFromSelectedObject = _useCiqTableUtils.removeRowFromSelectedObject,
      nestedRowToSelectedKey = _useCiqTableUtils.nestedRowToSelectedKey;
    var selectedRows = computed(function () {
      if (props.dataSource === "fmp") {
        return selectedFMPFinancialRows.value;
      } else if (props.dataSource === "morningstar") {
        return selectedMorningstarFinancialRows.value;
      }
      return selectedCIQFinancialRows.value;
    });
    var generateRowClasses = function generateRowClasses(_ref) {
      var darkMode = _ref.darkMode,
        row = _ref.row,
        segmentName = _ref.segmentName,
        _ref$title = _ref.title,
        title = _ref$title === void 0 ? true : _ref$title;
      var classArray = row.format.split(" ");
      if (!title) {
        classArray = [];
      } else if (classArray.includes("lineabove")) {
        if (darkMode) {
          classArray.push("whitelineabove");
        } else {
          classArray.push("blacklineabove");
        }
      }
      if (row.formula === "h3") {
        classArray.push("heading");
      }
      var rowId = nestedRowToSelectedKey(row, segmentName);
      if (selectedRows.value[rowId]) {
        classArray.push("clicked");
      }
      return classArray;
    };
    var finSegRowClick = function finSegRowClick(lineitem, segmentName) {
      var rowId = nestedRowToSelectedKey(lineitem, segmentName);
      if (selectedRows.value[rowId]) {
        removeRowFromSelectedObject(rowId, props.period, "financialsChart", props.dataSource);
      } else {
        addRowToSelectedObject(lineitem, rowId, props.period, "financialsChart", props.dataSource);
      }
      return rowId;
    };
    var _useBaseUtils = useBaseUtils(),
      freezeFirstColumn = _useBaseUtils.freezeFirstColumn,
      darkActive = _useBaseUtils.darkActive,
      I18nFn = _useBaseUtils.I18nFn;
    return {
      I18nFn: I18nFn,
      generateRowClasses: generateRowClasses,
      finSegRowClick: finSegRowClick,
      selectedRows: selectedRows,
      freezeFirstColumn: freezeFirstColumn,
      darkActive: darkActive,
      nestedRowToSelectedKey: nestedRowToSelectedKey
    };
  },
  computed: {
    activePeriod: function activePeriod() {
      return this.$store.state.ciq.financialsPeriodToggle;
    },
    cleanDateHeaders: function cleanDateHeaders() {
      var annual = this.activePeriod === 0;
      var dateHeaders = this.dateHeaders;
      return annual && this.dataSource === "ciq" ? dateHeaders.filter(function (f) {
        return (f === null || f === void 0 ? void 0 : f.periodtypeid) === 1;
      }) : dateHeaders;
    },
    cleanLineitems: function cleanLineitems() {
      var allItems = this.lineitems.filter(function (f) {
        return (f === null || f === void 0 ? void 0 : f.tikrdisplay) === "1";
      });
      var dates = this.cleanDateHeaders.map(function (d) {
        return d.value;
      });
      var dataObj = this.segDataObj;
      var result = allItems.filter(function (f) {
        if (f.formula === "h3") {
          return true;
        }
        var innerKey = f === null || f === void 0 ? void 0 : f.dataitemid;
        var didObj = dataObj[innerKey];
        var keep = false;
        if (didObj) {
          var segKeys = Object.keys(didObj);
          segKeys.forEach(function (key) {
            var segDataObj = didObj[key];
            dates.forEach(function (dateKey) {
              var _segDataObj$dateKey;
              if ((_segDataObj$dateKey = segDataObj[dateKey]) !== null && _segDataObj$dateKey !== void 0 && _segDataObj$dateKey.v) {
                keep = true;
              }
            });
          });
        }
        return keep;
      });
      return result;
    }
  },
  methods: {
    formatFinancialClass: function formatFinancialClass(fin, date, lineitem) {
      var data = date ? fin[date] : undefined;
      var positiveColor = this.$vuetify.theme.dark ? "accent--text" : "black--text";
      if (data !== null && data !== void 0 && data.v) {
        var multiplyby = lineitem.multiplyby || 1;
        if (typeof data.v === "string") {
          var d = parseFloat(data.v) * multiplyby;
          return d > 0 ? positiveColor : "red--text";
        } else if (typeof data.v === "number") {
          var _d = data.v * multiplyby;
          return _d > 0 ? positiveColor : "red--text";
        }
      }
    },
    dataExistsForRow: function dataExistsForRow() {
      var dataObj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var dateArr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      var dataExists = false;
      dateArr.forEach(function (obj) {
        if (dataObj[obj.value]) {
          dataExists = true;
        }
      });
      return dataExists;
    }
  }
});