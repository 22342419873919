var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('table',{staticClass:"fintab"},[_c('colgroup',[_c('col'),_vm._v(" "),_vm._l((_vm.dateHeaders),function(da){return _c('col',{key:`${da.value}-${_vm.dataSource}-header`,staticClass:"datacol"})})],2),_vm._v(" "),_c('thead',[_c('tr',{staticClass:"tableheader"},[_c('th',{class:{
          'py-1': true,
          'pl-1': true,
          'text-left': true,
          fixedfirstheader: _setup.freezeFirstColumn,
          description: !_setup.freezeFirstColumn,
        }},[_vm._v("\n        "+_vm._s(`${_setup.I18nFn(_vm.tbltitle)}${
            _vm.$device.isDesktopOrTablet ? " | TIKR.com" : ""
          }`)+"\n      ")]),_vm._v(" "),_vm._l((_vm.dateHeaders),function(date,idx){return _c('th',{key:`${date.value}-${_vm.dataSource}-tikrHead`,class:{
          'text-right': true,
          'py-1': true,
          'pr-1': idx === _vm.dateHeaders.length - 1,
        }},[_vm._v("\n        "+_vm._s(_vm.showLtm && date.periodtypeid === 4 ? _setup.I18nFn("LTM") : date.text)+"\n      ")])})],2)]),_vm._v(" "),_c('tbody',[_c('tr',{staticClass:"empty"},[_vm._v("\n       \n    ")]),_vm._v(" "),_vm._l((_vm.lineitems),function(fin,finIdx){return [(fin.tikrdisplay === '1' || _vm.showAllEmptyRows)?_c('tr',{key:`${finIdx}-${fin.name}-${_vm.dataSource}-tikr`,class:_setup.generateRowClasses(
            fin,
            _setup.rowObjToSelectedKey,
            _setup.selectedRows,
            _setup.darkActive,
            _vm.dataSource
          ),on:{"click":function($event){return _setup.finRowClick(
            _setup.rowObjToSelectedKey,
            fin,
            _setup.selectedRows,
            'financialsChart',
            _vm.dataSource
          )}}},[(fin.formula === 'val' || fin.formula === 'sum')?[_c('td',{class:{
              fixedfirstrow: _setup.freezeFirstColumn,
              description: !_setup.freezeFirstColumn,
              fixedfirstrowcolorlight: !_setup.darkActive,
              fixedfirstrowcolordark: _setup.darkActive,
              'text-left': true,
            }},[_vm._v("\n            "+_vm._s(_setup.I18nFn(fin.name.trim()))+"\n            "),(_setup.glossary[fin.name.trim()] && _vm.showTurns)?[_c('v-tooltip',{attrs:{"bottom":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"x-small":"","color":_setup.iconColor}},on),[_vm._v("\n                    mdi-information-outline\n                  ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("\n                  "+_vm._s(_setup.glossary[fin.name.trim()])+"\n                ")])])]:_vm._e()],2),_vm._v(" "),(fin.datasource === 'valuation')?_vm._l((_vm.dateHeaders),function(d){return _c('ValuationExtraTblCell',{key:`${d.value}-${fin.name}-${_vm.dataSource}-valuation`,attrs:{"current-iso":_vm.currentIso,"data-obj":fin[d.value],"decimals":_vm.decimals,"desiredunits":_vm.desiredUnits,"format-class":_setup.formatFinancialClass(fin, d.value, _vm.$vuetify.theme.dark),"lineitem":fin}})}):_vm._l((_vm.dateHeaders),function(d){return _c('FinValueTblCell',{key:`${d.value}-${fin.name}-${_vm.dataSource}`,attrs:{"formatclass":_setup.formatFinancialClass(fin, d.value, _vm.$vuetify.theme.dark),"data-obj":fin[d.value],"latest-date":_setup.lastDateReported,"period":_vm.period,"date-obj":d,"lineitem":fin,"desiredunits":_vm.desiredUnits,"decimals":_vm.decimals,"exrate":d.exchangerate,"currencytoggle":_vm.currencyToggle,"showturns":_vm.showTurns,"data-source":_vm.dataSource}})})]:(fin.formula === 'h3')?[(
              _vm.lineitems.length - 1 > finIdx &&
              _vm.lineitems[finIdx + 1].formula !== 'h3'
            )?[_c('td',{class:{
                fixedfirstrow: _setup.freezeFirstColumn,
                description: !_setup.freezeFirstColumn,
                fixedfirstrowcolorlight: !_setup.darkActive,
                fixedfirstrowcolordark: _setup.darkActive,
                'text-left': true,
              }},[_vm._v("\n              "+_vm._s(_setup.I18nFn(fin.name.trim()))+"\n            ")])]:_vm._e()]:(fin.formula === 'dxdt')?[_c('td',{class:{
              fixedfirstrow: _setup.freezeFirstColumn,
              description: !_setup.freezeFirstColumn,
              fixedfirstrowcolorlight: !_setup.darkActive,
              fixedfirstrowcolordark: _setup.darkActive,
              'text-left': true,
            }},[_vm._v("\n               "+_vm._s(_setup.I18nFn(fin.name.trim()))+"\n            "),(_setup.glossary[fin.name.trim()] && _vm.showTurns)?[_c('v-tooltip',{attrs:{"bottom":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"x-small":"","color":_setup.iconColor}},on),[_vm._v("\n                    mdi-information-outline\n                  ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("\n                  "+_vm._s(_setup.glossary[fin.name.trim()])+"\n                ")])])]:_vm._e()],2),_vm._v(" "),_vm._l((_vm.dateHeaders),function(d){return _c('FinPctTblCell',{key:`${d.value}-${fin.name}-${_vm.dataSource}-dxdt`,attrs:{"data-obj":fin[d.value],"lineitem":fin,"formatclass":_setup.formatFinancialClass(fin, d.value, _vm.$vuetify.theme.dark),"currencytoggle":_vm.currencyToggle,"celltype":"dxdt"}})})]:(fin.formula === 'pct')?[_c('td',{class:{
              fixedfirstrow: _setup.freezeFirstColumn,
              description: !_setup.freezeFirstColumn,
              fixedfirstrowcolorlight: !_setup.darkActive,
              fixedfirstrowcolordark: _setup.darkActive,
              'text-left': true,
            }},[_vm._v("\n            "+_vm._s(_setup.I18nFn(fin.name.trim()))+"\n            "),(_setup.glossary[fin.name.trim()] && _vm.showTurns)?[_c('v-tooltip',{attrs:{"bottom":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"x-small":"","color":_setup.iconColor}},on),[_vm._v("\n                    mdi-information-outline\n                  ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("\n                  "+_vm._s(_setup.glossary[fin.name.trim()])+"\n                ")])])]:_vm._e()],2),_vm._v(" "),_vm._l((_vm.dateHeaders),function(d){return _c('FinPctTblCell',{key:`${d.value}-${fin.name}-${_vm.dataSource}-pct`,attrs:{"data-obj":fin[d.value],"lineitem":fin,"formatclass":_setup.formatFinancialClass(fin, d.value, _vm.$vuetify.theme.dark),"currencytoggle":_vm.currencyToggle,"celltype":fin.formula}})})]:(fin.formula === 'turns')?[_c('td',{class:{
              fixedfirstrow: _setup.freezeFirstColumn,
              description: !_setup.freezeFirstColumn,
              fixedfirstrowcolorlight: !_setup.darkActive,
              fixedfirstrowcolordark: _setup.darkActive,
              'text-left': true,
            }},[_vm._v("\n            "+_vm._s(_setup.I18nFn(fin.name.trim()))+"\n            "),(_setup.glossary[fin.name.trim()] && _vm.showTurns)?[_c('v-tooltip',{attrs:{"bottom":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"x-small":"","color":_setup.iconColor}},on),[_vm._v("\n                    mdi-information-outline\n                  ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("\n                  "+_vm._s(_setup.glossary[fin.name.trim()])+"\n                ")])])]:_vm._e()],2),_vm._v(" "),_vm._l((_vm.dateHeaders),function(d){return _c('FinValueTblCell',{key:`${d.value}-${fin.name}-${_vm.dataSource}-turns`,attrs:{"formatclass":_setup.formatFinancialClass(fin, d.value, _vm.$vuetify.theme.dark),"data-obj":fin[d.value],"latest-date":_setup.lastDateReported,"period":_vm.period,"lineitem":fin,"desiredunits":_vm.desiredUnits,"decimals":_vm.decimals,"exrate":d.exchangerate,"currencytoggle":_vm.currencyToggle,"showturns":_vm.showTurns}})})]:[_c('td',{class:{
              fixedfirstrow: _setup.freezeFirstColumn,
              description: !_setup.freezeFirstColumn,
              fixedfirstrowcolorlight: !_setup.darkActive,
              fixedfirstrowcolordark: _setup.darkActive,
              'text-left': true,
            }},[_vm._v("\n               "+_vm._s(_setup.I18nFn(fin.name.trim()))+"\n            "),(_setup.glossary[fin.name.trim()] && _vm.showTurns)?[_c('v-tooltip',{attrs:{"bottom":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"x-small":"","color":_setup.iconColor}},on),[_vm._v("\n                    mdi-information-outline\n                  ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("\n                  "+_vm._s(_setup.glossary[fin.name.trim()])+"\n                ")])])]:_vm._e()],2),_vm._v(" "),_vm._l((_vm.dateHeaders),function(d){return _c('FinPctTblCell',{key:`${d.value}-${fin.name}-${_vm.dataSource}-calc`,attrs:{"data-obj":fin[d.value],"lineitem":fin,"formatclass":_setup.formatFinancialClass(fin, d.value, _vm.$vuetify.theme.dark),"currencytoggle":_vm.currencyToggle}})})]],2):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }