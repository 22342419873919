import "core-js/modules/es.array.includes.js";
import { MORNINGSTAR_LTM } from "~/feature-toggle";
var usePeriod = function usePeriod(props) {
  var periods = computed(function () {
    return [{
      value: "annual",
      period: "a",
      title: "Annual",
      disabled: false
    }, {
      value: "quarter",
      period: "q",
      title: "Quarterly",
      disabled: false
    }, {
      value: "semiAnnual",
      period: "semi",
      title: "Semi Annual",
      disabled: ["morningstar", "fmp"].includes(props.activeTableSelection.value)
    }, {
      value: "ltm",
      period: "ltm",
      title: "LTM",
      disabled: MORNINGSTAR_LTM ? ["fmp"].includes(props.activeTableSelection.value) : ["fmp", "morningstar"].includes(props.activeTableSelection.value)
    }];
  });
  var period = computed(function () {
    return periods.value[props.activePeriod.value].period;
  });
  return {
    period: period,
    periods: periods
  };
};
export default usePeriod;