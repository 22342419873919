import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import useBaseUtils from "~/functions/useBaseUtils";
import useCiqTableUtils from "~/functions/useCiqTableUtils";
export default {
  __name: 'CiqFinancialTable',
  props: {
    tbltitle: {
      type: String,
      "default": "Income Statement"
    },
    dateHeaders: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    lineitems: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    metricObj: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    desiredUnits: {
      type: Number,
      "default": 1
    },
    decimals: {
      type: Number,
      "default": 2
    },
    currencyToggle: {
      type: Number,
      "default": 0
    },
    showTurns: {
      type: Boolean,
      "default": true
    },
    showAllEmptyRows: {
      type: Boolean,
      "default": true
    },
    period: {
      type: String,
      "default": "a"
    },
    showLtm: {
      type: Boolean,
      "default": false
    },
    dataSource: {
      type: String,
      "default": "ciq"
    },
    currentIso: {
      type: String,
      "default": "USD"
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useBaseUtils = useBaseUtils(),
      freezeFirstColumn = _useBaseUtils.freezeFirstColumn,
      darkActive = _useBaseUtils.darkActive,
      I18nFn = _useBaseUtils.I18nFn;
    var _useCiqTableUtils = useCiqTableUtils(props),
      selectedCIQFinancialRows = _useCiqTableUtils.selectedCIQFinancialRows,
      selectedFMPFinancialRows = _useCiqTableUtils.selectedFMPFinancialRows,
      selectedMorningstarFinancialRows = _useCiqTableUtils.selectedMorningstarFinancialRows,
      addRowToSelectedObject = _useCiqTableUtils.addRowToSelectedObject,
      rowObjToSelectedKey = _useCiqTableUtils.rowObjToSelectedKey,
      removeRowFromSelectedObject = _useCiqTableUtils.removeRowFromSelectedObject,
      formatFinancialClass = _useCiqTableUtils.formatFinancialClass,
      glossary = _useCiqTableUtils.glossary;
    var selectedRows = computed(function () {
      if (props.dataSource === "fmp") {
        return selectedFMPFinancialRows.value;
      }
      if (props.dataSource === "morningstar") {
        return selectedMorningstarFinancialRows.value;
      }
      return selectedCIQFinancialRows.value;
    });
    var generateRowClasses = function generateRowClasses(row, rowToKeyFn, selectedRowsObj, darkMode, dataSource) {
      var classArray = row.format.split(" ");
      if (classArray.includes("lineabove")) {
        classArray.push(darkMode ? "whitelineabove" : "blacklineabove");
      }
      if (row.formula === "h3") {
        classArray.push("heading");
      }
      var rowToKeyOptions = {};
      var alternateDataSource = dataSource !== "ciq";
      if (alternateDataSource) {
        rowToKeyOptions.prefix = dataSource;
        rowToKeyOptions.delimiter = "-";
        rowToKeyOptions.rowPropertyForMap = "key";
        rowToKeyOptions.metricMap = {};
      }
      var rowId = alternateDataSource ? rowToKeyFn(row, rowToKeyOptions) : rowToKeyFn(row);
      if (selectedRowsObj[rowId]) {
        classArray.push("clicked");
      }
      return classArray;
    };
    var finRowClick = function finRowClick(rowToKeyFn, lineitem, selectedRowsObj) {
      var chartKey = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "financialsChart";
      var vuexModule = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "ciq"; // row... in CiqFinancialTable is an object
      /**
         * {
         *    periodId1: {},
         *    periodId2: {},
         *    dataitemid,
         *    name,
         *    format,
         *    formula,
         *    lineorder
         *    multipleby
         *    tikrdisplay
         * }
         *
         * the periodId1 objects look like
         * { u: unittypeid, v: "String Value of a Number"}
         * unless it is a calculation in which case the values are numbers
         * (the results of a calculation)
         */
      if (lineitem.formula === "h3") {
        return;
      }
      var rowToKeyOptions = {};
      var alternateDataSource = vuexModule !== "ciq";
      if (alternateDataSource) {
        rowToKeyOptions.prefix = vuexModule;
        rowToKeyOptions.delimiter = "-";
        rowToKeyOptions.rowPropertyForMap = "key";
        rowToKeyOptions.metricMap = {};
      }
      var rowId = alternateDataSource ? rowToKeyFn(lineitem, rowToKeyOptions) : rowToKeyFn(lineitem);
      if (selectedRowsObj[rowId]) {
        removeRowFromSelectedObject(rowId, props.period, chartKey, vuexModule);
      } else {
        addRowToSelectedObject(lineitem, rowId, props.period, chartKey, vuexModule);
      }
    };
    var iconColor = computed(function () {
      return darkActive.value ? "white" : "#838383";
    });
    var lastDateReported = computed(function () {
      var firstDate = props.dateHeaders[0];
      var _props$dateHeaders$re = props.dateHeaders.reduce(function (max, obj) {
          return obj.timeVal > max.timeVal;
        }, firstDate),
        fiperiodenddate = _props$dateHeaders$re.fiperiodenddate;
      return fiperiodenddate;
    });
    return {
      __sfc: true,
      props: props,
      freezeFirstColumn: freezeFirstColumn,
      darkActive: darkActive,
      I18nFn: I18nFn,
      selectedCIQFinancialRows: selectedCIQFinancialRows,
      selectedFMPFinancialRows: selectedFMPFinancialRows,
      selectedMorningstarFinancialRows: selectedMorningstarFinancialRows,
      addRowToSelectedObject: addRowToSelectedObject,
      rowObjToSelectedKey: rowObjToSelectedKey,
      removeRowFromSelectedObject: removeRowFromSelectedObject,
      formatFinancialClass: formatFinancialClass,
      glossary: glossary,
      selectedRows: selectedRows,
      generateRowClasses: generateRowClasses,
      finRowClick: finRowClick,
      iconColor: iconColor,
      lastDateReported: lastDateReported
    };
  }
};